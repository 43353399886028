import React from "react";

const projects = [
  {
    title: "Portfolio Website",
    description: "A personal portfolio website to showcase my skills and projects.",
    link: "https://your-portfolio-link.com",
    image: "https://example.com/portfolio-image.jpg", // Add image URL here
  },
  {
    title: "Weather App",
    description: "A React app that shows real-time weather info using a public API.",
    link: "https://your-weather-app-link.com",
    image: "https://example.com/weather-app-image.jpg", // Add image URL here
  },
  {
    title: "Task Manager",
    description: "A simple task management app built with React and Tailwind CSS.",
    link: "https://github.com/your-username/task-manager",
    image: "https://example.com/task-manager-image.jpg", // Add image URL here
  },
];

const ProjectPage = () => {
  return (
    <div className="min-h-screen bg-gray-100 py-12 px-4">
      <h1 className="text-4xl font-bold text-center mb-10">Projects</h1>
      <div className="max-w-6xl mx-auto grid gap-8 md:grid-cols-2 lg:grid-cols-2">
        {projects.map((project, index) => (
          <div
            key={index}
            className="bg-white p-8 rounded-2xl shadow-lg hover:shadow-xl transition duration-300"
          >
            <img
              src={project.image}
              alt={project.title}
              className="w-full h-64 object-cover rounded-lg mb-4"
            />
            <h2 className="text-2xl font-semibold mb-2">{project.title}</h2>
            <p className="text-gray-600 mb-4">{project.description}</p>
            <a
              href={project.link}
              target="_blank"
              rel="noopener noreferrer"
              className="text-indigo-600 hover:text-indigo-800 font-medium"
            >
              View Project &rarr;
            </a>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ProjectPage;

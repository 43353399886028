import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Navbar from "./Navbar"; // Import your Navbar component
import Footer from "./Footer"; // Import your Footer component

// Import pages
import HomePage from "./pages/HomePage";
import ContactPage from "./pages/ContactPage";
import ProjectPage from "./pages/ProjectPage"
import ResumePage from "./pages/ResumePage"

function App() {
  return (
    <Router>
      <Navbar /> {/* Navbar stays on top */}
        <Routes>
        <Route path="/" element={<HomePage />} />
          <Route path="/contact" element={<ContactPage />} />
          <Route path="/projects" element={<ProjectPage />} />
          <Route path="/resume" element={<ResumePage />} />
        </Routes>
      <Footer /> {/* Footer stays at the bottom */}
    </Router>
  );
}

export default App;

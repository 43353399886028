import React from "react";

const Footer = () => {
  return (
    <footer className="bg-gray-900 text-white py-6">
      <div className="max-w-screen-xl mx-auto text-center">
        <p className="text-sm">&copy; 2025 MyWebsite. All rights reserved.</p>
        <div className="mt-4">
          <a href="/contact" className="text-gray-400 hover:text-white mx-3">
            Privacy Policy
          </a>
          <a href="/contact" className="text-gray-400 hover:text-white mx-3">
            Terms of Service
          </a>
          <a href="/contact" className="text-gray-400 hover:text-white mx-3">
            Contact Us
          </a>
        </div>
      </div>
    </footer>
  );
};

export default Footer;

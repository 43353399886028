import React, { useRef } from 'react';
import emailjs from '@emailjs/browser';

const ContactUs = () => {
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm('service_f7rbrkw', 'template_w5a4jzk', form.current, 'iysVlxPLgjVDBWfZ_')
      .then((result) => {
        console.log(result.text);
        alert('Message sent successfully!');
      })
      .catch((error) => {
        console.error(error.text);
        alert('Failed to send message.');
      });

    e.target.reset();
  };

  return (
    <div className="h-screen flex items-center justify-center bg-gray-900 text-white px-4">
      <div className="bg-gray-800 shadow-2xl rounded-2xl w-full max-w-4xl overflow-hidden flex flex-col md:flex-row">
        
        {/* Contact Form */}
        <div className="w-full md:w-1/2 p-8 flex flex-col justify-center">
          <p className="text-gray-300 mb-6 text-center">
            We'd love to hear from you! Please fill out the form below.
          </p>
          <form ref={form} onSubmit={sendEmail} className="space-y-4">
            <div>
              <input
                type="text"
                name="user_name"
                placeholder="Your name"
                required
                className="w-full px-4 py-2 border border-gray-600 bg-gray-700 text-white rounded-lg focus:ring-2 focus:ring-pink-400 outline-none"
              />
            </div>
            <div>
              <input
                type="email"
                name="user_email"
                placeholder="Your email"
                required
                className="w-full px-4 py-2 border border-gray-600 bg-gray-700 text-white rounded-lg focus:ring-2 focus:ring-pink-400 outline-none"
              />
            </div>
            <div>
              <textarea
                name="message"
                placeholder="Your message"
                rows="4"
                required
                className="w-full px-4 py-2 border border-gray-600 bg-gray-700 text-white rounded-lg focus:ring-2 focus:ring-pink-400 outline-none"
              ></textarea>
            </div>
            <button
              type="submit"
              className="w-full bg-blue-600 text-white font-semibold py-2 rounded-lg transition hover:bg-blue-700"
            >
              Send Message
            </button>
          </form>
        </div>

        {/* Contact Info */}
        <div className="w-full md:w-1/2 bg-gray-700 p-8 flex flex-col justify-center text-center">
          <h3 className="text-2xl font-semibold text-blue-400 mb-4">Get in touch</h3>
          <p className="text-gray-300 mb-4">You can also reach me through the following ways:</p>
          <div className="space-y-2 text-gray-200">
            <p><strong>Email:</strong> contact@gangabajgai.com</p>
            <p><strong>Phone:</strong> (123) 456-7890</p>
            <p><strong>Address:</strong> Harrisburg, PA.</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactUs;

import React from 'react';
import { Typewriter } from 'react-simple-typewriter';

const HomePage = () => {
  return (
    <div className="h-screen flex flex-col justify-center items-center bg-gray-900 text-white text-center px-4">
      {/* Typewriter Section */}
      <div className="text-xl font-bold mb-6">
        <h1 className="text-5xl md:text-6xl font-bold mb-4">Hi, I'm Ganga.</h1>
        <span>
          <Typewriter
            words={['Business Analyst', 'Scrum Master', 'Front End Developer']}
            loop={true}
            cursor
            cursorStyle="|"
            typeSpeed={100}
            deleteSpeed={50}
            delaySpeed={1000}
          />
        </span>
      </div>

      {/* Description */}
      <p className="text-lg md:text-xl text-gray-400 mb-8 max-w-2xl">
      A passionate Business Analyst with a strong analytical mindset and a growing interest in front-end development, bridging business needs with technical solutions to drive impactful results.
      </p>

      {/* Buttons */}
      <div className="flex flex-col md:flex-row md:space-x-6 space-y-4 md:space-y-0">
        <a
          href="/projects"
          className="px-6 py-3 bg-indigo-600 text-white font-semibold rounded-lg shadow-md hover:bg-indigo-700 transition"
        >
          View My Work
        </a>
        <a
          href="/contact"
          className="px-6 py-3 border border-white text-white font-semibold rounded-lg shadow-md hover:bg-white hover:text-gray-900 transition"
        >
          Contact Me
        </a>
      </div>
    </div>
  );
};

export default HomePage;

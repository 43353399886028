import React from "react";

const experiences = [
  {
    title: "Technical Business Analyst",
    company: "WebstaurantStore Inc",
    date: "January 2022 – Present",
    description: [
      "Performed analysis and gathering requirements for multiple project initiatives.",
      "Acted as a liaison between the Development team and the business customers.",
      "Assumed all Scrum Master duties.",
    ],
    link: "/contact",
  },
  {
    title: "Category Management Analyst",
    company: "Rite Aid Corporation",
    date: "September 2021 – January 2022",
    description: [
      "Collect data from sales, marketing, and finance departments to use in establishing organizational structure.",
      "Present detailed reports about the meaning of gathered data to members of management and help them identify scenarios utilizing modifications in the data.",
      "Reported bi-monthly data analysis findings to upper management to use in making organizational decisions.",
    ],
    link: "/contact",
  },
  {
    title: "Businss Analyst",
    company: "Highmark Health Solutions Inc",
    date: "August 2020 – September 2021",
    description: [
      "Assisted with the deliverables and maintenance of requirements, user interface design, and personalized requirements to different clients.",
      "Designed and ran custom mysql queries for ticketing system organization.",
      "Created functional specification, technical requirements, and business use case documents for business reports.",
    ],
    link: "/contact",
  },
  {
    title: "Database Developer",
    company: "Pennsylvania State Data Center",
    date: "September 2019 – December 2019",
    description: [
      "Centralized data mysql scripts to improve accessibility and maintenance for analysts and administrators from having to search multiple databases.",
      "Optimized query performance and populated test data.",
      "Performed quality assurance and testing of SQL server environment.",
    ],
    link: "/contact",
  },
  {
    title: "Business Analyst",
    company: "D&H Distriburing",
    date: "May 2019 - August 2019",
    description: [
      "Facilitated the process of gathering requirements and making sure the projects were meeting the deadlines between Business Analysts, Quality Assurance, and Developers.",
      "Provided executives with analytics and decision-support tools used as the basis for reorganization, consolidation and relocation strategies.",
      "Create supporting data in the testing environments where all the functionalities are tested as per Use Cases.",
    ],
    link: "/contact",
  },
  // Add more experiences here
];

const certifications = [
    {
      name: "Certified ScrumMaster (CSM)",
      organization: "Scrum Alliance",
      date: "March 2021",
    },
    {
      name: "Advanced Certified ScrumMaster (A-CSM)",
      organization: "Scrum Alliance",
      date: "July 2022",
    },
    {
      name: "Team Kanban Practitioner (TKP)",
      organization: "Kanban University",
      date: "November 2023",
    },
    // Add more certifications as needed
  ];
  

  const ResumePage = () => {
    return (
      <div className="min-h-screen bg-gray-900 text-white py-12 px-4">
        <h1 className="text-4xl font-bold text-center mb-10">Experiences</h1>
        <div className="relative border-l-4 border-indigo-500 max-w-4xl mx-auto space-y-12 pl-6">
          {experiences.map((experience, index) => (
            <div key={index} className="relative group">
              <span className="absolute -left-3 top-2 w-6 h-6 bg-indigo-500 rounded-full border-4 border-gray-900 z-10"></span>
              <div className="bg-gray-800 p-6 rounded-2xl shadow-lg transition duration-300">
                <div className="flex justify-between items-start flex-col sm:flex-row sm:items-center">
                  <div>
                    <h2 className="text-xl font-semibold text-white">{experience.title}</h2>
                    <p className="text-sm text-gray-400">{experience.company}</p>
                  </div>
                  <span className="text-sm text-gray-400 sm:ml-4 mt-2 sm:mt-0">{experience.date}</span>
                </div>
                <ul className="list-disc list-inside text-gray-300 mt-4 space-y-2">
                  {experience.description.map((point, i) => (
                    <li key={i}>{point}</li>
                  ))}
                </ul>
                <a
                  href={experience.link}
                  rel="noopener noreferrer"
                  className="inline-block mt-4 text-indigo-400 hover:text-indigo-200 font-medium"
                >
                  Learn More &rarr;
                </a>
              </div>
            </div>
          ))}
        </div>
  
        {/* Certifications Section */}
        <h1 className="text-4xl font-bold text-center mt-20 mb-10">Certifications</h1>
        <div className="max-w-4xl mx-auto space-y-6">
          {certifications.map((cert, index) => (
            <div key={index} className="bg-gray-800 p-6 rounded-2xl shadow-lg transition duration-300">
              <div className="flex justify-between items-center flex-col sm:flex-row">
                <div>
                  <h3 className="text-xl font-semibold text-white">{cert.name}</h3>
                  <p className="text-sm text-gray-400">{cert.organization}</p>
                </div>
                <span className="text-sm text-gray-400 sm:ml-4 mt-2 sm:mt-0">{cert.date}</span>
              </div>
            </div>
          ))}
        </div>
      </div>
    );
  };
  

export default ResumePage;

import React from "react";

const Navbar = () => {
  return (
    <nav className="bg-gray-900 text-white shadow-md sticky top-0 w-full z-10">
      <div className="max-w-screen-xl mx-auto px-4 py-2 flex justify-center items-center">
        {/* Menu */}
        <div className="flex space-x-8">
          <a
            href="/"
            className="text-2xl font-bold hover:text-gray-400 transition duration-300"
          >
            Home
          </a>
          <a
            href="/projects"
            className="text-2xl font-bold hover:text-gray-400 transition duration-300"
          >
            Projects
          </a>
          <a
            href="/resume"
            className="text-2xl font-bold hover:text-gray-400 transition duration-300"
          >
            Resume
          </a>
          <a
            href="/contact"
            className="text-2xl font-bold hover:text-gray-400 transition duration-300"
          >
            Contact
          </a>
        </div>

        {/* Mobile Menu Button */}
        <div className="md:hidden ml-4">
          <button className="text-white focus:outline-none">
            <svg
              className="w-6 h-6"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M4 6h16M4 12h16M4 18h16"
              />
            </svg>
          </button>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
